import React, {
    useState,
    useEffect,
    useCallback,
    useMemo,
    useRef,
} from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import qs from 'qs';
import _ from 'lodash';
import history from '../../history';
import Spinner from '../Utils/Spinner';
import Product from './Product';
import { showProductDetails } from '../../actions/productDetails';
import { toggleProductFavorite } from '../../actions/routines';

const BoxProductContainer = ({
    onFetchData,
    currentPage,
    hasMore,
    loading,
    data = [],
    deliveryDate,
    ...props
}) => {
    const [products, setProducts] = useState([]);
    const [page, setPage] = useState(1);
    const [limit] = useState(50);
    const onFetchDataRef = useRef(onFetchData);

    useEffect(() => {
        onFetchDataRef.current = onFetchData;
    }, [onFetchData]);

    useEffect(() => {
        const queryParams = qs.parse(history.location.search.slice(1));
        queryParams.limit = limit.toString();
        onFetchDataRef.current(queryParams);
    }, [limit]);

    useEffect(() => {
        setProducts((prevProducts) =>
            currentPage === 1 ? data : [...prevProducts, ...data]
        );

        if (currentPage === 1) {
            setPage(1);
        }
    }, [data, currentPage]);

    const handleOpenProductDetails = useCallback(
        (id) => {
            const { showProductDetails } = props;
            showProductDetails(id, deliveryDate);
        },
        [showProductDetails, deliveryDate]
    );

    const handleLoadMore = useCallback(() => {
        if (loading || !hasMore) return;

        const queryParams = qs.parse(history.location.search.slice(1));
        queryParams.limit = limit;
        queryParams.page = page + 1;

        setPage((prevPage) => prevPage + 1);
        onFetchData(queryParams);
    }, [loading, hasMore, limit, page, onFetchData]);

    const handleToggleProductFavorite = useCallback(
        (id) => {
            const { toggleProductFavorite } = props;
            toggleProductFavorite(id);
            setProducts((prevProducts) =>
                prevProducts.map((product) =>
                    product.id === id
                        ? { ...product, isFavourite: !product.isFavourite }
                        : product
                )
            );
        },
        [toggleProductFavorite]
    );

    const uniqueProducts = useMemo(
        () => _.uniqWith(products, _.isEqual),
        [products]
    );

    if (loading && page === 1) {
        return (
            <div className="d-flex justify-content-center my-4">
                <Spinner className="brand-color" />
            </div>
        );
    }

    if (uniqueProducts.length === 0 && page === 1) {
        return null;
    }

    return (
        <Product
            hasMore={hasMore}
            isLoading={loading}
            loadMore={handleLoadMore}
            products={uniqueProducts}
            openProductDetails={handleOpenProductDetails}
            toggleProductFavorite={handleToggleProductFavorite}
        />
    );
};

BoxProductContainer.propTypes = {
    onFetchData: PropTypes.func.isRequired,
    hasMore: PropTypes.bool.isRequired,
    currentPage: PropTypes.number.isRequired,
    loading: PropTypes.bool.isRequired,
    data: PropTypes.array,
    deliveryDate: PropTypes.string,
    showProductDetails: PropTypes.func.isRequired,
    toggleProductFavorite: PropTypes.func.isRequired,
};

BoxProductContainer.defaultProps = {
    data: [],
};

const mapStateToProps = (state) => ({
    deliveryDate: state.deliveryDatepicker.deliveryDate,
});

const mapDispatchToProps = {
    showProductDetails,
    toggleProductFavorite,
};
console.log('mapDispatchToProps', mapDispatchToProps);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BoxProductContainer);
