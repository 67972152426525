import PropTypes from 'prop-types';
import React from 'react';
import { Route as BaseRoute } from 'react-router-dom';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import { Container } from 'mdbreact';

const pageMobileSupport = ['/cart'];

const HeaderFooterRoute = ({
    component: Component,
    withHeader,
    withFooter,
    fluid = false,
    path,
    ...props
}) => (
    <BaseRoute
        {...props}
        render={(renderProps) => (
            <div>
                {withHeader && <Header {...renderProps} />}
                <Container
                    fluid={fluid}
                    className={`${
                        withHeader ? 'container--fixed-header' : ''
                    } ${
                        pageMobileSupport.includes(path)
                            ? 'mobile-paddings'
                            : ''
                    }`}
                >
                    <Component {...renderProps} />
                    {withFooter && <Footer />}
                </Container>
            </div>
        )}
    />
);

HeaderFooterRoute.propTypes = {
    withFooter: PropTypes.bool,
    withHeader: PropTypes.bool,
};

export default HeaderFooterRoute;
