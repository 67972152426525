import TitleHeader from '../components/TitleHeader';
import { Spinner } from 'mdbreact';
import React from 'react';
import PropTypes from 'prop-types';
import { newReleaseGrey } from '../../../assets/images/icons';
import InfiniteScroll from 'react-infinite-scroll-component';
import ProductBox from '../../BoxProduct/components/ProducBox';

const Novelty = ({
    products,
    hasMore,
    isLoading,
    loadMore,
    title,
    titleValues,
    openProductDetails,
    toggleProductFavorite,
}) => {
    return (
        <div className="row">
            <div className="col">
                <TitleHeader
                    icon={newReleaseGrey}
                    title={title}
                    values={titleValues}
                ></TitleHeader>

                <InfiniteScroll
                    className="mb-5 box-products"
                    hasMore={!isLoading && hasMore}
                    next={loadMore}
                    dataLength={products.length}
                    loader={
                        <div className="flex-center">
                            {' '}
                            <Spinner className="brand-color" />
                        </div>
                    }
                >
                    {products.map((product, index) => {
                        return (
                            <ProductBox
                                index={index}
                                key={product.id}
                                product={product}
                                openProductDetails={openProductDetails}
                                toggleProductFavorite={toggleProductFavorite}
                                isNoveltyPage
                            />
                        );
                    })}
                </InfiniteScroll>
            </div>
        </div>
    );
};

Novelty.propTypes = {
    hasMore: PropTypes.bool,
    isLoading: PropTypes.any,
    loadMore: PropTypes.func,
    products: PropTypes.array,
    title: PropTypes.string,
    titleValues: PropTypes.object,
};

Novelty.defaultProps = {
    title: 'menu.novelty',
    titleValues: {},
};

export default Novelty;
