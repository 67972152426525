import React, { useEffect, useState } from 'react';
import InputDefault from '../../../Form/InputDefault';

const OrderNumberInput = () => {
    const [valid, setValid] = useState('');
    const [inputValue, setInputValue] = useState('');

    useEffect(() => {
        let isValid = inputValue.length <= 10;

        setValid(isValid);

        if (isValid) {
            sessionStorage.setItem('customerOrderNumber', inputValue);
        }
    }, [inputValue]);

    return (
        <InputDefault
            placeholder="cart.order_number.placeholder"
            className={'order-number-input' + (valid ? '' : ' is-invalid')}
            invalidFeedback="cart.order_number.error"
            onChange={(e) => setInputValue(e.target.value)}
            value={inputValue}
            isClearButton={true}
            isValid={valid}
            onFocusHandler={() => setInputValue('')}
        />
    );
};

export default OrderNumberInput;
